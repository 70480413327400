<template>
  <div class="mobile-container">
    <mobileStatusBar title="设置密码" />
    <div class="input-item">
      <div class="input"><input v-model="Pwd" :type="showPwd ? 'text' : 'password'" placeholder="请设置新密码" /></div>
      <div class="icon img-cover" @click="showPwd = !showPwd">
        <img v-if="showPwd" src="../../assets/images/not-password-icon.png">
        <img v-else src="../../assets/images/password-icon.png">
      </div>
    </div>
    <div class="input-item">
      <div class="input"><input v-model="SurePwd" :type="showSurePwd ? 'text' : 'password'" placeholder="请确认密码" /></div>
      <div class="icon img-cover" @click="showSurePwd = !showSurePwd">
        <img v-if="showSurePwd" src="../../assets/images/not-password-icon.png">
        <img v-else src="../../assets/images/password-icon.png">
      </div>
    </div>
    <div class="tips-item">请设置6-20位密码</div>
    <div class="flex-1"></div>
    <div class="btn-item public-btn" @click="handleSubmit">
      <van-loading v-if="submiting" color="#ffffff" />
      <span v-else>保存</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "ResetPwd",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      showPwd: false,
      showSurePwd: false,
      Pwd: '',
      SurePwd: ''
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {

  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleSubmit() {
      const { Pwd, SurePwd } = this
      if (Pwd == '') {
        this.$toast('请输入新密码')
        return
      }
      if (Pwd.length < 6 || Pwd.length > 20) {
        this.$toast('请设置6-20位密码')
        return
      }
      if (SurePwd == '') {
        this.$toast('请再次输入新密码')
        return
      }
      if (SurePwd.length < 6 || SurePwd.length > 20) {
        this.$toast('请设置6-20位密码')
        return
      }
      if (Pwd != SurePwd) {
        this.$toast('两次输入密码不一致')
        return
      }
      var res = await this.$API.userInfo.post({
        Switch: 2,
        Pwd: Pwd
      })
      if (res.error == 0) {
        this.$toast.success(res.info)
        this.GetUserInfo()
        this.$router.go(-1)
        return true
      } else {
        this.$dialog.alert({
          type: 'danger',
          title: '提示',
          message: res.info,
          confirmButtonText: '确定',
        })
        return false
      }
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.input-item {
  width: 6.38rem;
  height: .88rem;
  margin-top: .32rem;
  padding: 0 .24rem;
  background-color: #fff;
  border-radius: .16rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input {
    flex: 1;
  }
  .icon {
    cursor: pointer;
    width: .4rem;
    height: .4rem;
    margin-left: .24rem;
  }
}
.tips-item {
  margin-top: .24rem;
  width: 6.62rem;
  line-height: .34rem;
  color: #b3b3b3;
  font-size: .24rem;
}
.btn-item {
  cursor: pointer;
  margin-bottom: 1.6rem;
  width: 6.86rem;
  height: .88rem;
  border-radius: .12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .3rem;
  font-weight: bold;
  color: #fff;
}
</style>